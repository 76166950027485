$primary-theme: #1a92d0;
$secondary-theme: #70d0f6;
$gray-bg-shape: #F2F2F3;
$gray-border: #e5e5e5;
$white: #FFFFFF;

body {
    background-color: $white;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

@media (min-width: 1025px) {
    .layout-wrapper .topbar-items {
        margin: 0;
    }
}

.navbar-header-title {
    margin: 0;
    color: white;
    font-weight: normal;
}

.client-navbar-header-title {
    margin: 0;
    color: white;
    font-weight: normal;
    font-size: 20;
}

.navbar-header-title-container {
    display: flex;
    align-items: center;
}
body .p-stripe {
    background-color: #fcffec;
}

.layout-main {
    height: 100%;
}


.logo {
    display: block;
    text-align: center;
    margin: 20px auto;
}

#practiceDiary {
    padding: 0;
}

.layout-wrapper .topbar .topbar-right {
    background-color: $primary-theme;
}

body {
    .p-toolbar {
        background-color: $secondary-theme;
        padding: 0em;
    }

    .p-button {
        background-color: $primary-theme;
    }

    .p-selectbutton .p-button.p-state-active {
        background-color: $secondary-theme;
    }

    .p-menu .p-menuitem-link{
        font-size: 95%
    }
}


/* control panel dashboard */
.control-panel-dashboard {
    & > div {
        display: flex;
        padding: 1em;
    }

    button {
        margin: 0;
        border-radius: 8px;
    }

    .fa {
        margin-right: 1em;
    }

    hr {
        padding: 0;
        border: 1px solid $gray-border;
    }

}

.cs-drop-shadow {
    -webkit-box-shadow: 2px 2px 5px 0px rgba(16,139,232,0.45);
    -moz-box-shadow: 2px 2px 5px 0px rgba(16,139,232,0.45);
    box-shadow: 2px 2px 5px 0px rgba(16,139,232,0.45);}

.bg-container {
    border: 2px solid $gray-border;
}

.control-panel-input {
    & > * {
        flex: auto;
    }
}

.bg-shape {
    width: 0;
    height: 0;
    position: fixed;

    &.bg-shape-1 {
        border-bottom: 280px solid $secondary-theme;
        border-right: 320px solid transparent;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    &.bg-shape-2 {
        border-bottom: 560px solid $gray-bg-shape;
        border-right: 1240px solid transparent;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    &.bg-shape-3 {
        border-bottom: 360px solid $primary-theme;
        border-left: 720px solid transparent;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}

.p-tooltip {
  z-index: 9;
}

.text-left {
  text-align: left!important;
}

.card {
    background: none;
}

.card-bg-solid {
    background: rgba(256,256,256,0.9);
    border: none;
}

.p-dropdown-auto-width .p-dropdown {
    min-width: 5em;
}

.p-spinner-auto-width .p-spinner {
    min-width: unset;
}

body .tp-appointment-list .p-accordion .p-accordion-header {
    & > a {
        display: flex;
        align-items: center;

        .p-accordion-header-text {
            flex: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}



#appointmentSummary {
    height: 100%;
}

/* Missing Teeth / Prognosis */

#tooth-row {
    display: flex;
}

#tooth-row .p-chkbox {
    display: block;
}

#tooth-row .p-chkbox-box {
    margin: 0 auto;
}


#tooth-row > div {
    flex-grow: 1;
    margin: 0 -3px;
}

#tooth-row label {
    display: block;
    margin-left: 5px;
    //text-align: center;
}

/* Tooth Wear Index */

#tooth-wear-row {
    display: flex;
    justify-content: space-between;
    padding-left: 1px;
    padding-right: 1px;
}

#tooth-wear-row .p-chkbox {
    display: block;
}

#tooth-wear-row .p-chkbox-box {
    margin: 0 auto;
}

#tooth-wear-row .p-widget {
    min-width:60px;
    width:5px;
}

#tooth-wear-row > div {
    margin: 0 1px;
    padding-left: 1px;
    padding-right: 1px;
}

#tooth-wear-row label {
    display: block;
    margin: 0;
    text-align: center;
    padding-left: 1px;
    padding-right: 1px;
    padding: 0;
}

/* CPITN */

#cpitn-row {
    display: flex;
}

#cpitn-row .p-widget {
    min-width:60px;
}

/**/

.right-text-label {
    text-align: right;
}
#toolbar-icons .p-button-icon-only .p-button-icon-left {
    margin-top: -0.85em;
    margin-left: -0.9em;
    width: 24px;
    height: 24px;
}

.appointment-booking-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
        display:flex;
        align-items: center;
    }
}

.icon-chevron-center {
    .fa-chevron-left,
    .fa-chevron-right {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#panel-header {
    margin-right: 1em;
}

#medConTitle .p-panel-titlebar {
    padding-top: 16px;
}

#panel-icons .p-button-icon-only .p-button-icon-left {
    margin-top: -0.40em;
    margin-left: -0.85em;
    width: 24px;
    height: 24px;
}

#section-icons .p-button-icon-only .p-button-icon-left {
    margin-top: -0.82em;
    margin-left: -0.80em;
    width: 24px;
    height: 24px;
}

#section-icons2 .p-button-icon-only .p-button-icon-left {
    margin-top: -0.85em;
    margin-left: -0.9em;
    width: 24px;
    height: 24px;
}

#chartingBody .p-card-body {

    padding-top: 0px;
    padding-bottom: 0px;
}

#pdfdropshadow {
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.12)
}

#p-tabview-panel {
    padding-left: 0px;
    padding-right: 0px;
}

/* DataTable Pagination extension */
.extended-pagination .p-paginator {
    .p-paginator-pages {
        vertical-align: top;
    }
    .p-dropdown {
        min-width: auto;
    }
    .p-dropdown-label.p-inputtext {
        padding-right: 2em;
    }
}

/* Toolbar icons fix */
.p-toolbar.p-component {
    .p-button-text {
        &.p-c {
            min-width: 1.3em;
        }
    }
}
.p-tabview-nav {

    font-size: 17px;
}
body .p-button.p-button-icon-only{

    font-size: 17px;
}

.control-panel-dashboard a {
    display: flex;
    flex: auto;
}

#onlyoffice-panel-header-only {
    .p-panel-content {
        display: none;
    }
}

.force-4-select-row .p-button{
            width: auto!important;

}

.bg-blue{
    background-color: rgb(211, 231, 254);
}

.bg-green{
    background-color: rgb(221, 233, 224);
}

.bg-white{
    background-color: $white;
}

.rbc-time-content{
    max-height: 700px;
}

.exception-body {
    height: 100%;
}

.p-menuitem-icon{
    width:1.5em;
}

.image-gallery-slide img {
    height: 30vh;
}

/* dog mgmt */
.wizard-body .wizard-wrapper {
    height: 50%;
    top: 50%;
}

.wizard-body {
    background-position-y: -35vh;
}


.p-mhform-member {
    background-color: #bfccf5;
    color: black;
}

.p-mhform-header {
    background-color: #60cb66;
    color: black;
}

// videos
.video-wrapper {
    position: relative;
}

.video-remote {
    width: 100%;
}

.video-local {
    position: absolute;
    right: 60px;
    bottom: 60px;
    width: 25%;
}

.reg-question-box {
    background-color: rgb(52, 143, 207);
    color: white;
    border-style: solid;
    border: white;
    border-width: 5em;
}

.reg-details-box {
    background-color: rgb(52, 143, 207);
    color: white;
    border-style: solid;
    border: white;
    border-width: 5em;
}


.p-button.p-button-registration, .p-buttonset.p-button-registration > .p-button {
    background-color: #292b2c;
    border-color: #292b2c;

    &:enabled:hover {
        background-color: darken(#348fcf,5%);
        border-color: #348fcf;
    }

    &:enabled:focus {
        outline: 0 none;
    }

    &:enabled:active  {
        background-color: darken(#79b1d9,10%);
        border-color: #348fcf;
    }
}
.vl {
    border-left: 6px solid white;
    height: 500px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 0;
}

div.nhsImage {
    background: url('/assets/images/nhs_logo16.png');
    background-repeat: no-repeat;
    height: 1.2em;
    width: 2.8em;
}

.readonly-quill .ql-editor {
    height: unset!important;
}
