$primary-theme: #1a92d0;
$secondary-theme: #70d0f6;
$gray-bg-shape: #F2F2F3;
$gray-border: #e5e5e5;
$white: #FFFFFF;

.registration-body {
    height: 100%;
    overflow: visible;

    .registration-image {
        height: 100vh;
        position: relative;

        &::after {
            content: ' ';
            position: absolute;
            background-image: url(/assets/layout/images/login/chiralLogin2.jpg);
            background-size: cover;
            background-position: 50% 10%;
            height: calc(100vh - 400px);
            width: 100%;
            bottom: 400px;
            left: 0;

            @media (min-width: 1080px) {
                background-image: url(/assets/layout/images/login/chiralLogin2.jpg);
            }
        }
    }

    .registration-image2 {
        height: 100vh;
        position: relative;

        &::after {
            content: ' ';
            position: absolute;
            background-image: url(/assets/layout/images/login/clientLogin2.jpg);
            background-size: cover;
            background-position: 50% 10%;
            height: calc(100vh - 400px);
            width: 100%;
            bottom: 400px;
            left: 0;

            @media (min-width: 1080px) {
                background-image: url(/assets/layout/images/login/clientLogin2.jpg);
            }
        }
    }

    .registration-image3 {
        height: 100vh;
        position: relative;

        &::after {
            content: ' ';
            position: absolute;
            background-image: url(/assets/layout/images/login/labLogin3.jpg);
            background-size: cover;
            background-position: 50% 10%;
            height: calc(100vh - 400px);
            width: 100%;
            bottom: 400px;
            left: 0;

            @media (min-width: 1080px) {
                background-image: url(/assets/layout/images/login/labLogin3.jpg);
            }
        }
    }

    .login-panel {
        width: 100%;
        height: 400px;
    }

    .login-panel .login-panel-content {
        color: $white;
    }
}
