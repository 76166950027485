.body2{
    margin:0;
    color:#6a6f8c;
    background: #348fcf;
    font:600 16px/18px 'Open Sans',sans-serif;
}
*,:after,:before{box-sizing:border-box}
.clearfix:after,.clearfix:before{content:'';display:table}
.clearfix:after{clear:both;display:block}
a{color:inherit;text-decoration:none}

.login-wrap{
    width:100%;
    margin:auto;
    max-width:525px;
    position:relative;
    box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
}
.login-html{
    width:100%;
    height:100%;
    position:absolute;
    padding:50px 70px 50px 70px;
    background:rgba(40,57,101,.8);
}
.login-practicename-html{
    width:100%;
    height:100%;
    position:absolute;
    padding:50px 70px 50px 70px;
    background: rgb(52, 143, 207);
    color: #ffffff;
}
.login-html .sign-in-htm,
.login-html .sign-up1-htm,
.login-html .sign-up2-htm{
    top:0;
    left:0;
    right:0;
    bottom:0;
    position:absolute;
}
.login-html .sign-in,
.login-html .sign-up1,
.login-html .sign-up2,
.login-form .group .check{
    display:none;
}
.login-html .tab,
.login-form .group .label,
.login-form .group .button{
    text-transform:uppercase;
    color: #348fcf;
}
.login-html .tab{
    font-size:15px;
    margin:0 15px 10px 0;
    display:inline-block;
    border-bottom:2px solid transparent;
}
.login-html .sign-in:checked + .tab,
.login-html .sign-up1:checked + .tab,
.login-html .sign-up2:checked + .tab{
    color:#fff;
    border-color:#1161ee;
}
.login-form{
    min-height:400px;
    position:relative;
    perspective:1000px;
    transform-style:preserve-3d;
}
.login-form .group{
    margin-bottom:15px;
}
.login-form .group .label,
.login-form .group .input,
.login-form .group .button{
    width:100%;
    color:#fff;
    display:block;
}
.login-form .group .input,
.login-form .group .button{
    border:none;
    padding:15px 20px;
    border-radius:5px;
    background:rgba(255,255,255,.1);
}
.login-form .group input[data-type="password"]{
    text-security:circle;
    -webkit-text-security:circle;
}
.login-form .group .label{
    color:#aaa;
    font-size:12px;
}
.login-form .group .button{
    background:#1161ee;
}
.login-form .group label .icon{
    width:15px;
    height:15px;
    border-radius:2px;
    position:relative;
    display:inline-block;
    background:rgba(255,255,255,.1);
}
.login-form .group label .icon:before,
.login-form .group label .icon:after{
    content:'';
    width:10px;
    height:2px;
    background:#fff;
    position:absolute;
    transition:all .2s ease-in-out 0s;
}
.login-form .group label .icon:before{
    left:3px;
    width:5px;
    bottom:6px;
    transform:scale(0) rotate(0);
}
.login-form .group label .icon:after{
    top:6px;
    right:0;
    transform:scale(0) rotate(0);
}
.login-form .group .check:checked + label{
    color:#fff;
}
.login-form .group .check:checked + label .icon{
    background:#1161ee;
}
.login-form .group .check:checked + label .icon:before{
    transform:scale(1) rotate(45deg);
}
.login-form .group .check:checked + label .icon:after{
    transform:scale(1) rotate(-45deg);
}

.hr{
    height:2px;
    margin:60px 0 50px 0;
    background:rgba(255,255,255,.2);
}
.foot-lnk{
    text-align:center;
    color: white;
}

.overview-box {
    padding: 5px;
    color: #ffffff;
    min-height: 75px;
    border-radius: 5px;
}

.overview-box .overview-box-title {
    font-weight: bold;
    width: 100%;
}

.overview-box .overview-box-title i {
    vertical-align: middle;
    font-size: 12px;
}

.overview-box .overview-box-title span {
    font-size: 12px;
    margin-left: .5em;
    vertical-align: middle;
}

.overview-box .overview-box-count {
    color: #ffffff;
    font-size: 12px;
    width: 100%;
    display: block;
    padding: 2px 0;
}

.overview-box .overview-box-2 {
    border: solid 1px rgba(67, 69, 96, 0.1);
}